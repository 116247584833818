<template>
    <v-dialog
        v-model="display"
        max-width="700px"
        :fullscreen="$is_mobile"
        transition="dialog-bottom-transition"
        v-if="dates.length > 0"
    >
        <!-- ----------------------------------------------------- TEXT FIELD -->
        <template v-slot:activator="{ on }">
            <v-text-field
                :background-color="backgroundColor"
                :label="label"
                :disabled="disabled"
                :dense="dense"
                prepend-inner-icon="mdi-calendar"
                outlined
                readonly
                v-on="on"
                :value="final_dates_display"
            ></v-text-field>
        </template>
        <!-- ----------------------------------------------------- DIALOG -->

        <v-card class='pa-5 text-center'>
            <v-card-title class='justify-center mb-5'>
                <!-- <span v-if="!$is_mobile">Réserver pour</span> -->
                <from-to
                    :from="start_date"
                    :to="end_date"
                    :vertical="$is_mobile"
                    :dense="$is_mobile"
                ></from-to>
            </v-card-title>

            <!-- ----------------------------- date picker -->
            <v-date-picker
                :no-title="$is_mobile"
                v-if="is_mids"
                class='dater'
                v-model="dates[0]"
                color="primary"
                :locale="$i18n.locale"
                first-day-of-week="1"
                elevation="5"
                :allowed-dates="allowed_dates"
            ></v-date-picker>
            <v-date-picker
                :no-title="$is_mobile"
                v-else
                class='dater'
                v-model="dates"
                range
                color="primary"
                :locale="$i18n.locale"
                first-day-of-week="1"
                elevation="5"
                :allowed-dates="allowed_dates"
            ></v-date-picker>
            <!-- ----------------------------- timer sliders -->
            <div :class='["time_slider text-left mt-7",$is_mobile ? "mx-5" : ""]'>
                <!-- ------------------------- MIDS -->
                <div
                    v-if="is_mids"
                    style="display:flex; flex-wrap: wrap; justify-content: center"
                >
                    <!--<v-spacer></v-spacer>-->
                    <v-btn
                        v-for="(range,index) in hours"
                        :key="index"
                        class='mx-1'
                        color="primary"
                        elevation="0"
                        :text="!is_mid_range_selected(range)"
                        :disabled="is_passed(range)"
                        @click="times = [[range[0],0],[range[1],0],[range[2],0]]"
                    >
                        {{format_hour(range[0])}}:00
                        <v-icon
                            small
                            class='mx-2'
                        >mdi-arrow-right</v-icon>
                        {{format_hour(range[1])}}:00
                    </v-btn>
                    <!--<v-spacer></v-spacer>-->
                </div>
                <!-- ------------------------- multi date -->
                <v-row
                    class='ma-0'
                    v-else
                >
                    <v-col
                        class='ma-0 pa-0'
                        :cols="$is_mobile && is_date_range ? 12 : 5"
                        style="display:flex"
                    >
                        <v-spacer></v-spacer>
                        <time-selecter
                            v-model="times[0]"
                            :title="is_date_range ? display_dates[0] : null"
                            :hours="allowed_blocks[0].hours"
                            :minutes="allowed_blocks[0].minutes"
                        >
                        </time-selecter>
                        <v-spacer v-if="$is_mobile && is_date_range"></v-spacer>
                    </v-col>
                    <v-col
                        :cols="$is_mobile && is_date_range ? 12 : 2"
                        style="display:flex"
                    >
                        <v-spacer></v-spacer>
                        <v-icon
                            class="mr-3 ml-3"
                            small
                        >mdi-arrow-{{$is_mobile && is_date_range ? 'down' : 'right'}}</v-icon>
                        <v-spacer></v-spacer>
                    </v-col>
                    <v-col
                        class='ma-0 pa-0'
                        :cols="$is_mobile && is_date_range ? 12 : 5"
                        style="display:flex"
                    >
                        <v-spacer v-if="$is_mobile && is_date_range"></v-spacer>
                        <time-selecter
                            v-model="times[1]"
                            :title="is_date_range ? display_dates[1] : null"
                            :hours="allowed_blocks[1].hours"
                            :minutes="allowed_blocks[1].minutes"
                            :last="true"
                        >
                        </time-selecter>
                        <v-spacer></v-spacer>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions class='mt-10'>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="cancel"
                >{{$t('cancel')}}</v-btn>
                <v-btn
                    color="primary"
                    @click="validate"
                >{{$t('validate')}}</v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>
import { format, parse } from 'date-fns'
import FromTo from './from-to.vue'
import TimeSelecter from './time-selecter.vue'
/*
@desc Date Time selection system featuring 4 time modes: mids, jumps, hours, quarters
*/
export default {
    props: ['value', 'day_hours', 'background-color', 'label', 'dense', 'disabled', 'time_mode'],
    components: { FromTo, TimeSelecter },
    data: () => ({
        display: true,

        dates: [],
        times: [[9, 0], [19, 0]],

        final_dates_display: '',

        start_hour: 1,
        end_hour: 24,

        modes: ['mids', 'jumps', 'hours', 'quarters'],
        test_mode: 'quarters'

    }),
    watch: {
        dates: {
            handler() {
                if (this.dates[0] === this.dates[1]) this.dates.splice(0, 1)
            },
            deep: true
        }
    },
    computed: {
        is_mids() {
            return this.time_mode == 'mids'
        },
      selectable_blocks() {
        const now = this.now();
        const start_hour = this.start_hour;
        const end_hour = this.end_hour;
        const default_mode = this.time_mode ?? 'jumps';

        // Calculer l'heure de fin minimale en ajoutant 12 heures à l'heure de début
        const minEndHour = start_hour + 12;
        const adjustedEndHour = minEndHour > end_hour ? minEndHour : end_hour;

        // Ajustement des heures en fonction du mode
        const hours = {
          'mids': () => [[start_hour, adjustedEndHour], [start_hour, 14], [12, adjustedEndHour]],
          'jumps': () => {
            const availableHours = [];
            for (let h = start_hour; h <= adjustedEndHour; h++) {
              availableHours.push(h);
            }
            return availableHours;
          },
          'hours': () => Array(adjustedEndHour - start_hour + 1).fill(0).map((_, i) => i + start_hour),
          'quarters': () => {
            const hoursArray = [];
            for (let h = start_hour; h <= adjustedEndHour; h++) {
              hoursArray.push(h);
            }
            return hoursArray;
          }
        }[this.time_mode ?? default_mode]();

        // Ajustement des minutes (quarts d'heure)
        const minutes = {
          'mids': () => [],
          'jumps': () => [0],
          'hours': () => [0],
          'quarters': () => {
            const nowMinutes = now.getMinutes();
            const currentQuarter = Math.floor(nowMinutes / 15) * 15; // Quart d'heure entamé
            const currentHour = now.getHours();

            // Si l'heure sélectionnée correspond à l'heure actuelle
            if (start_hour === currentHour) {
              // Pour l'heure actuelle, commencer au dernier quart d'heure entamé
              const allowedMinutes = [0, 15, 30, 45].filter(minute => minute >= currentQuarter);
              return allowedMinutes.length ? allowedMinutes : [0, 15, 30, 45]; // Si aucun quart d'heure valide, repartir sur le prochain cycle
            } else {
              // Pour les heures futures, permettre tous les quarts d'heure
              return [0, 15, 30, 45];
            }
          }
        }[this.time_mode ?? default_mode]();

        // Filtrage des heures de fin pour respecter la contrainte des 12 heures
        const endHours = hours.filter(hour => hour >= start_hour + 12); // Assurer qu'il y a au moins 12 heures entre l'heure de début et l'heure de fin

        return { hours: endHours, minutes };
      },

        allowed_blocks() {
          const now = this.now();
          const blocks = this.selectable_blocks;
          const first_datetime = new Date(this.dates[0]);
          first_datetime.setHours(...this.times[0]);

          const dates = this.two_dates.sort((d1, d2) => this.$utils.check_date(d1) - this.$utils.check_date(d2));

          const allowed_blocks = dates.map((dstr, i) => {
            const date = new Date(dstr);

            const hours = blocks.hours.filter((h) => {
              const d = new Date(date);
              d.setHours(h, 0, 0, 0);
              return d >= now || (i === 0 && h === now.getHours());
            });

            const minutes = i === 0
                ? blocks.minutes.filter(m => {
                  const d = new Date(date);
                  d.setHours(hours[0], m, 0, 0); // Vérifie les minutes pour la première date
                  return d >= now || (hours.includes(now.getHours()) && m >= Math.floor(now.getMinutes() / 15) * 15);
                })
                : blocks.minutes;

            return { ...blocks, hours, minutes, last: i === 1 };
          });

          return allowed_blocks;
        },

        hours() {
            return this.selectable_blocks.hours
        },
        two_dates() {
            return this.is_date_range ? this.dates : [this.dates[0], this.dates[0]]
        },
        is_date_range() {
            return this.dates.length == 2
        },
        full_dates() {
            return this.two_dates.map((d, i) => {
                const time = this.times[i]
                const date_obj = new Date(d)
                date_obj.setHours(...time)
                return date_obj
            })
        },
        working_dates() {
            return this.full_dates.sort((d1, d2) => {
                return this.$utils.check_date(d1) - this.$utils.check_date(d2)
            })
        },
        display_dates() {
            return this.working_dates.map(d => format(this.$utils.check_date(d), 'dd/MM/yyyy'))
        },
        final_dates() {
            return this.working_dates.map((d, i) => {
                const time = this.times[i]
                const date_obj = new Date(d)
                date_obj.setHours(...time)
                return date_obj
            })
        },
        start_date() {
            return this.final_dates[0]
        },
        end_date() {
            return this.final_dates[1]
        },
    },
    methods: {
        now() {
            const now = new Date()
            return now
        },
        is_mid_range_selected(range) {
            return range.reduce((a, h, i) => a && h == this.times[i][0], true)
        },
        update_date_display() {
            this.final_dates_display = `${this.$utils.format(this.start_date)} ${this.$t('time-filter.and')} ${this.$utils.format(this.end_date)}`
        },
        allowed_dates(date_str) {
            const date = parse(date_str, 'yyyy-MM-dd', this.now())
            date.setHours(0, 0, 0, 0)
            const start_date = this.now()
            start_date.setHours(0, 0, 0, 0)
            return date >= start_date && (date > start_date || this.now().getHours() < this.end_hour - 1)
        },
        cancel() {
            this.display = false
        },
        validate() {
            this.display = false
            this.update_date_display()
            this.$emit('input', this.final_dates)
        },
        is_passed(range){
          const now = this.now()
          const first_datetime = new Date(this.dates[0])
          const end_hour = range[1]
          first_datetime.setHours(end_hour, 0,0 ,0)
          if (first_datetime <= now) return true
          else return false
        },
        format_hour(hour) {
          console.log(hour)
          return hour === 24 ? '0' : hour; // Si l'heure est 24, afficher 0
        },
    },

    mounted() {
      const now = this.now();

      if (this.value.length > 0 && this.value[0]) {
        const d1 = format(this.value[0], 'yyyy-MM-dd');
        const d2 = format(this.value[1], 'yyyy-MM-dd');

        const t1 = format(this.value[0], 'HH:mm').split(':').map(e => parseInt(e));
        const t2 = format(this.value[1], 'HH:mm').split(':').map(e => parseInt(e));

        this.dates.push(...[d1, d2]);
        this.times[0] = t1;
        this.times[1] = t2;
        this.update_date_display();
      } else {
        const today = now;
        if (!this.allowed_dates(format(today, 'yyy-MM-dd'))) {
          today.setDate(today.getDate() + 1);
        }
        const today_str = format(today, 'yyyy-MM-dd');
        this.dates.push(today_str);

        if (this.is_mids) {
          this.times = this.hours[0].map(h => [h, 0]);
        } else {
          const firstAllowedHour = this.allowed_blocks[0].hours[0];
          const firstAllowedMinute = this.allowed_blocks[0].minutes[0];
          this.times[0] = [firstAllowedHour, firstAllowedMinute];
          this.times[1] = [[...this.allowed_blocks[1].hours].pop(), 0];
        }
      }
    }
}
</script>

<style>
.dater {
    margin-left: auto;
    margin-right: auto;
}
</style>
